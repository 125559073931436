import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero2 from '../../images/slider/slide-3.jpg'
import hero3 from '../../images/slider/slide-4.jpg'

import './style.css'



class Hero extends Component {
    render() {

        var settings = {
            dots: false,
            arrows: true,
            speed: 1200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            fade: true
        };

        return (
            <section className="hero hero-slider hero-style-1">
                <Slider {...settings}>
                    <div className="slide">
                        <div className="slide-inner" style={{ backgroundImage: `url(${this.props.Hero})` }}>
                            <div className="container">
                                <div className="col col-lg-6 slide-caption">
                                    <div className="slide-title">
                                        <h2>Looking to break last years <span>Donations</span></h2>
                                    </div>
                                    <div className="slide-subtitle">
                                        <p>Mouthy Mercs is a charity organization</p>
                                        <p>You can by help us raise donations for a great cause</p>
                                    </div>
                                    <div className="btnss">
                                     <form action="https://www.paypal.com/donate" method="post" target="_top">

                                        <input type="hidden" name="hosted_button_id" value="KBW74662LLT2A" />

                                        <button type="submit" className="theme-btn">Donate Now</button>

                                        </form>
                                        <Link target="_blank" to="https://forms.gle/7qqLagHEa2R6AU7c9" className="theme-btn-s2">Join Us!</Link>
                                        <Link target="_blank" to="https://mouthymercs-for-midwestshelter2023.itemorder.com/shop/home/?fbclid=IwAR1WhPk19eiLyl9e30F2Y1x-lUcoHgxgAoxA_GphNp5MOcIunKsSdQ5biB0" className="theme-btn-s2">Mercs Store</Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="slide">
                        <div className="slide-inner" style={{ backgroundImage: `url(${hero2})` }}>
                             <div className="container">
                                <div className="col col-lg-6 slide-caption">
                                    <div className="slide-title">
                                        <h2>Let’s be Kind for <span>Children</span></h2>
                                    </div>
                                    <div className="slide-subtitle">
                                        <p>High Quality Charity Theme in Envato Market.</p>
                                        <p>You Can Satisfied Yourself By Helping.</p>
                                    </div>
                                    <div className="btns">
                                        <Link to="/donate" className="theme-btn">Donate Now</Link>
                                        <Link to="/about" className="theme-btn-s2">Know More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="slide">
                        <div className="slide-inner" style={{ backgroundImage: `url(${hero3})` }}>
                            <div className="container">
                                <div className="col col-lg-6 slide-caption">
                                    <div className="slide-title">
                                        <h2>Let’s be Kind for <span>Children</span></h2>
                                    </div>
                                    <div className="slide-subtitle">
                                        <p>High Quality Charity Theme in Envato Market.</p>
                                        <p>You Can Satisfied Yourself By Helping.</p>
                                    </div>
                                    <div className="btns">
                                        <Link to="/donate" className="theme-btn">Donate Now</Link>
                                        <Link to="/about" className="theme-btn-s2">Know More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </Slider>
            </section>
        )
    }
}

export default Hero;