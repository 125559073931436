import React from 'react'
import {Link} from 'react-router-dom'
import VideoModal from  '../ModalVideo'
import abimg2 from '../../images/signature.png'
import './style.css'

const About = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="about-style-2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-12 col-12">
                        <div className="about-img">
                            <img src={props.AbImg} alt=""/>
                            <VideoModal/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 col-12">
                        <div className="about-content">
                            <div className="section-title">
                                <h2>About Us</h2>
                            </div>

                           <p>We're a group of hockey players
out to have fun playing the game we love. We are based out in the Chicago suburbs. We originally formed as a team to play in the "Puck Cancer" tournament in 2019. We have a wide range of player talent on our teams. We have coaches and players that have spent years playing, and we also have players that are newer to the sport and have not played as long. We're a family oriented team with multiple 
father/son combos, and multiple families with more than one skater. We
started off playing in the Men's C2 division.</p>
<p>

Over the past few years our group of players has exploded. We started off playing only one tournament a summer. In 2021, the Mercs now have four teams in three tournaments and a league team. Besides the "Puck Cancer" tournament, we are playing in "Pucks for Autism" tournaments and other charity events. 

Since many people ask, our team name comes from the original "Merc with a mouth", Deadpool. Merc is a mercenary.
We wear a teal and white ribbon on our shoulder to honor one of our
fans, Destiny, that has battled cervical cancer. We play for
Destiny and all the others that have battled cancer in any form. We will try to play for any and all causes that we can.
</p>
                            {/* <div className="btns">
                                <div><Link  onClick={ClickHandler} className="theme-btn" to="/about">More About Us..</Link></div>
                            </div> */}
                            <div className="signature">
                                <img src={abimg2} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;