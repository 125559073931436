import React from 'react'
import event1 from '../../images/event/img-1.png'
import event2 from '../../images/event/img-2.jpg'
import event3 from '../../images/event/img-3.png'
import {Link} from 'react-router-dom'

import './style.css'

const EventSection = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <section className="event-section section-padding">
            <div className="container">
                <div className="section-title text-center">
                    <span>Our Events</span>
                    <h2>Upcoming Events</h2>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="event-grids clearfix">
                            <p> 2024 events coming soon </p>
                            {/* <div className="grid">
                                <div className="img-holder">
                                    <img src={event1} alt="" />
                                </div>
                                <div className="details">
                                    <ul className="entry-meta">
                                        <li><Link target="_blank" onClick={ClickHandler} to="https://pucksforautism.com/upcoming-events"><i className="ti-calendar"></i> 14 April 2023</Link></li>
                                        {/* <li><Link onClick={ClickHandler} to="/event-details"><i className="ti-folder"></i> Education</Link></li> 
                                    </ul>
                                    <h3><Link target="_blank" onClick={ClickHandler} to="https://pucksforautism.com/upcoming-events">Pucks For Autisim</Link></h3>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={event1} alt="" />
                                </div>
                                <div className="details">
                                    <ul className="entry-meta">
                                        <li><Link target="_blank" onClick={ClickHandler} to="https://pucksforautism.com/upcoming-events"><i className="ti-calendar"></i> 23 June 2023</Link></li>
                                        {/* <li><Link onClick={ClickHandler} to="/event-details"><i className="ti-folder"></i> Education</Link></li> 
                                    </ul>
                                    <h3><Link target="_blank" onClick={ClickHandler} to="https://pucksforautism.com/upcoming-events">Pucks For Autisim</Link></h3>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                     <img src={event3} alt="" />
                                </div>
                                <div className="details">
                                    <ul className="entry-meta">
                                        <li><Link onClick={ClickHandler} to="/event-details"><i className="ti-calendar"></i> 14 July 2023</Link></li>
                                        {/* <li><Link onClick={ClickHandler} to="/event-details"><i className="ti-folder"></i> Treatment</Link></li> 
                                    </ul>
                                    <h3><Link onClick={ClickHandler} to="/event-details">Puck Cancer</Link></h3>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSection;