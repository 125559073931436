import React from 'react'
import './style.css'

const CounterSection = (props) => {
    return(
        <div className={`tp-counter-area ${props.counter}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="tp-counter-grids">
                            <div className="grid">
                                <div>
                                    <h2><span className="odometer" data-count="1500">$40,000</span>+</h2>
                                </div>
                                <p>in Donation</p>
                            </div>
                            <div className="grid">
                                <div>
                                    <h2><span className="odometer" data-count="7">10</span>+</h2>
                                </div>
                                <p>Tournaments Raised</p>
                            </div>
                            <div className="grid">
                                <div>
                                    <h2><span className="odometer" data-count="45">100</span>+</h2>
                                </div>
                                <p>Players</p>
                            </div>
                             <div className="grid">
                                <div>
                                    <h2><span className="odometer" data-count="2">7</span>+</h2>
                                </div>
                                <p>Championships</p>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CounterSection;