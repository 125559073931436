import React from 'react'
import {Link} from 'react-router-dom'
import './style.css'

const CtaSection = (props) => {

    return(
        <div className="tp-cta-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="tp-cta-text">
                            <h2>You can help us!</h2>
                            <p>Last year the Mercs helped raise over $10,000 in donations to charities. This year we are going to DOUBLE that!</p>
                            <div className="btns">
                            <form action="https://www.paypal.com/donate" method="post" target="_top">

                            <input type="hidden" name="hosted_button_id" value="KBW74662LLT2A" />

                            <button type="submit" className="theme-btn">Donate Now</button>

                            </form>
                                {/* <Link to="/donate" className="theme-btn">Donate Now</Link> */}
                                <Link target="_blank" to="https://forms.gle/7qqLagHEa2R6AU7c9" className="theme-btn-s2">Join Us!</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CtaSection;